// exportiert alle unter states


import { ActivityState, CustomerState, DebtorState, DocumentState, FileState, FinancingMapState, HouseholdState, LiabilityState, MasterDataState, NewLiabilityState, RealEstateState, SignatureState, UserState } from '@ucba/sdk/statemanagement/states';

import { ApplicationState } from './application/application.state';

export * from './application/application.state';

/**
 * Alle States welche über NGXS Verwaltet werden
 */
export const allStates = [
    ApplicationState,
    MasterDataState,
    UserState,
    CustomerState,
    ActivityState,
    FinancingMapState,
    RealEstateState,
    HouseholdState,
    LiabilityState,
    NewLiabilityState,
    DebtorState,
    DocumentState,
    FileState,
    SignatureState,
]

/**
 * Alle States welche im sessionStorage persisitiert werden
 */
export const persistStates = [
    ApplicationState.stateName,
    MasterDataState.stateName,
]
