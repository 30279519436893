import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalSettings } from '@ucba/sdk';
import { IActionButtonInterface } from '@ucba/sdk/interfaces';

import { IFunctionMenuButton } from '../../../../interfaces';

/**
 * Komponete welche ein menü bereitstellt, welches verschiedene Funktionsbuttons hat
 */
@Component({
    selector: 'finad-function-menu',
    templateUrl: './function-menu.component.html',
    styleUrls: ['./function-menu.component.scss'],
})
export class FunctionMenuComponent implements OnInit {

    @Input() public navigationButtons?: IFunctionMenuButton[];
    @Input() public actionButtons?: IFunctionMenuButton[];
    @Input() public activeButtonId?: string | undefined;

    @Output() public navigationBtnClicked = new EventEmitter<string>();
    @Output() public actionBtnClicked = new EventEmitter<IActionButtonInterface>();

    public isExpanded = true;

    public inputAcceptString = GlobalSettings.validUploadMimeTypes.join(',');

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.activeButtonId = !!this.activeButtonId ? this.activeButtonId : Array.isArray(this.navigationButtons) ? this.navigationButtons[0]?.id : undefined;
        // Die Navigation ist im Bereich "Neuer Kunde" nicht sichtbar, eine Hervorhebung ist somit weder im Dashbaord noch dort nötig
        if (this.activeButtonId === 'customer/edit') {
            this.activeButtonId = undefined;
        }
    }

    /**
     * aufruf sobald einer ein Navigationsbutton geklickt wurde
     *
     * @param {string} id id des Buttons
     */
    public onNavigationButtonClicked(id: string): void {
        this.activeButtonId = id;
        this.navigationBtnClicked.emit(id);
    }

    /**
     * aufruf sobald einer ein Actionbutton geklickt wurde
     *
     * @param {string} id id des Buttons
     * @param {string} type Typ des Buttons
     * @param {Event} event event des Buttons
     */
    public onActionButtonClicked(id: string, type?: string, event?: Event): void {
        if (type === 'file') {
            // #15264
            const fileInput = document.getElementById('file-upload') as HTMLInputElement;
            if (fileInput) {
                fileInput.click();
                fileInput.addEventListener('change', (e: Event) => {
                    this.actionBtnClicked.emit({ id, event: e });
                }, { once: true });
            }
        } else {
            this.actionBtnClicked.emit({ id, event });
        }
    }
}
