<mat-sidenav-container class="sidenav-container" autosize>

    <mat-sidenav class="sidenav" mode="side" opened="true" [disableClose]="true">
        <mat-nav-list class="header">
            <mat-list-item (click)="isExpanded=!isExpanded">
                <span *ngIf="!isExpanded" mat-list-icon class="material-icons" matTooltip="erweitern">unfold_more</span>
                <span *ngIf="isExpanded" mat-list-icon class="material-icons" matTooltip="schließen">unfold_less</span>
                <span *ngIf="isExpanded">Zuklappen</span>
            </mat-list-item>
        </mat-nav-list>

        <mat-nav-list *ngIf="navigationButtons && navigationButtons.length > 0" class="nav-buttons"
            [class.centered]="!actionButtons || actionButtons.length === 0">
            <mat-list-item *ngFor="let btn of navigationButtons" (click)="onNavigationButtonClicked(btn.id)"
                class="nav-btn" [matTooltip]="!!btn.tooltip ? btn.tooltip : btn.label"
                [class.btn-disabled]="!!btn.disabled" [class.nav-active]="(activeButtonId === btn.id)">
                <span matListItemTitle>
                    <span class="material-icons" mat-list-icon [matBadge]="btn.badge" matBadgeSize="small"
                        [class.active]="(activeButtonId === btn.id)">{{btn.icon}}</span>
                    <span *ngIf="isExpanded" class="nav-label" [class.nav-label-active]="(activeButtonId === btn.id)">
                        {{btn.label}}</span>
                    <span *ngIf="!!btn.upperIcon$" [class.active]="(activeButtonId === btn.id)"
                        class="material-icons upper-icon md-small" mat-list-icon>
                        {{(btn.upperIcon$ | async)}}
                    </span>
                </span>
            </mat-list-item>
        </mat-nav-list>

        <mat-nav-list *ngIf="actionButtons && actionButtons.length > 0" class="action-buttons">
            <mat-list-item *ngFor="let btn of actionButtons" [matTooltip]="!!btn.tooltip ? btn.tooltip : btn.label"
                [class.btn-disabled]="!!btn.disabled" [hidden]="btn.invisible"
                (click)="onActionButtonClicked(btn.id, btn.type, $event)">
                <ng-container *ngIf="btn.type !== 'file'">
                    <label class="upload-area">
                        <span class="material-icons" mat-list-icon [matBadge]="btn.badge"
                            matBadgeSize="small">{{btn.icon}}</span>
                        <p *ngIf="isExpanded">{{btn.label}}</p>
                    </label>
                </ng-container>
                <ng-container *ngIf="btn.type === 'file'">
                    <label for="upload" class="upload-area">
                        <span class="material-icons" mat-list-icon [matBadge]="btn.badge"
                            matBadgeSize="small">{{btn.icon}}</span>
                        <p *ngIf="isExpanded">{{btn.label}}</p>
                    </label>
                    <input id="file-upload" type="file" multiple="multiple" class="upload"
                        [accept]="inputAcceptString" />
                </ng-container>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <ng-content></ng-content>
</mat-sidenav-container>