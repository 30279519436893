import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { NtagReleaseNoteService } from '@ntag-ef/release-notes';
import { GlobalSettings } from '@ucba/sdk';
import { AccountController, ReleaseNotesController } from '@ucba/sdk/controller';
import { ActivitySortingType, FilterType, Role } from '@ucba/sdk/enums';
import { IListTuple } from '@ucba/sdk/interfaces';
import { IActivityCluster, IActivityModel } from '@ucba/sdk/models';
import { DataService, HelperService } from '@ucba/sdk/services';
import { ActivityState, MasterDataState, UserState } from '@ucba/sdk/statemanagement/states';
import { sort } from 'fast-sort';
import { MediaObserver } from 'ngx-flexible-layout';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { IFunctionMenuButton } from '../../interfaces/function-menu-button.interface';
import { AuthenticationService } from '../../services';

enum DashboardView {
    CustomerEdit = 'customer/edit',
    CustomerList = 'customer/list',
    Service = '',
}

type DashboardActivityCluster = IActivityCluster & { open: boolean };

/**
 * Dashboard Komponenten, zeigt navigationsbuttons und aktivitäten an
 */
@Component({
    selector: 'finad-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public UserSettings = GlobalSettings.userSettings;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public GlobalSettings = GlobalSettings;

    public activitySearch: FormControl;

    public sortList: IListTuple<string>[];
    public filterListConsult: IListTuple<string>[];
    public filterListBranch: IListTuple<string>[];

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public navButtons: IFunctionMenuButton[];

    public showUserManagementButton = false;

    public displayedActivities: DashboardActivityCluster[] = [];

    public isSmallScreen = false;

    public isLoading = false;

    /**
     * Standard Konstruktor zum initialisieren der default -sortierung/ -filterung
     * 
     * @param {TranslateService} translate TranslateService injector
     * @param {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param {Router} router Router injector
     * @param {ActivatedRoute} route ActivatedRoute injector
     * @param {Store} store Store injector
     * @param {DataService} dataService DataService Hub
     * @param {ReleaseNotesController} releaseNotesController ReleaseNotesController
     * @param {NtagReleaseNoteService} ntagReleaseNoteService NtagReleaseNoteService
     * @param {AuthenticationService} authService AuthenticationService injector
     * @param {MediaObserver} mediaObserver MediaObserver injector
     */
    public constructor(
        private translate: TranslateService,
        private enumTranslate: EnumTranslationService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private dataService: DataService,
        private releaseNotesController: ReleaseNotesController,
        private ntagReleaseNoteService: NtagReleaseNoteService,
        private authService: AuthenticationService,
        private mediaObserver: MediaObserver,
    ) {
        this.activitySearch = new FormControl<string>(this.UserSettings.activitySearch);

        this.sortList = HelperService.enumToListTuple(ActivitySortingType, 'ActivitySortingType', this.enumTranslate);

        this.filterListBranch = [];

        this.filterListConsult = Object.values(FilterType).map<IListTuple<string>>(t => ({
            value: t.toString(),
            label: this.enumTranslate.instant(
                { type: 'FilterType', value: t },
                { label: this.translate.instant('components.dashboard.activities.title') },
            ) as string,
        }));

        this.navButtons = [
            {
                id: DashboardView.CustomerEdit,
                icon: 'person_add',
                label: this.translate.instant('components.dashboard.buttons.newCustomer'),
                tooltip: this.translate.instant('components.dashboard.buttons.newCustomerTooltip'),
            },
            {
                id: DashboardView.CustomerList,
                icon: 'group',
                label: this.translate.instant('components.dashboard.buttons.customers'),
                tooltip: this.translate.instant('components.dashboard.buttons.customersTooltip'),
            },
            {
                id: DashboardView.Service,
                icon: 'home_repair_service',
                label: this.translate.instant('components.dashboard.buttons.service'),
                tooltip: this.translate.instant('components.dashboard.buttons.serviceTooltip'),
            },
        ];
    }

    /**
     * sortiert die übergebenen Aktivitäten nach dem übergebenen typ
     * 
     * @param {Array<IActivityModel>} clusters zu sortierende Aktivitätencluster
     * @param {ActivitySortingType} type sortier type
     * @returns {Array<IActivityModel>} sortierte Aktivitätencluster
     */
    public static sortActivities(clusters: IActivityCluster[], type: ActivitySortingType): IActivityCluster[] {
        switch (type) {

            case ActivitySortingType.NameAscending:
                return sort(clusters).asc(({ customerName }) => customerName);

            case ActivitySortingType.NameDescending:
                return sort(clusters).desc(({ customerName }) => customerName);

            case ActivitySortingType.CreatedAscending:
                return sort(clusters).asc(({ lastModified }) => lastModified);

            case ActivitySortingType.CreatedDescending:
                return sort(clusters).desc(({ lastModified }) => lastModified);

            default:
                return clusters;
        }
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.showUserManagementButton = this.authService.hasAtLeastOneRole([Role.Administrator])

        combineLatest([
            this.store.select(MasterDataState.branchesAsListTuple),
            this.store.select(UserState.branchIds),
        ])
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(([allBranches, branchIds]) => {
                const branchesOfUser = allBranches.filter(({ value }) => !!value && branchIds.includes(value));
                this.filterListBranch = sort(branchesOfUser).asc(({ label }) => label);
            });

        this.store.select(ActivityState.current)
            .pipe(
                takeUntil(this.viewLeft$),
                map(clusters => clusters.map<DashboardActivityCluster>(c => ({
                    ...c,
                    open: this.displayedActivities
                        .find(({ customerId, financingMapId }) => customerId === c.customerId && financingMapId === c.financingMapId)?.open ?? false,
                }))),
            )
            .subscribe(cluster => {
                this.displayedActivities = cluster;
            });

        this.activitySearch.valueChanges.pipe(
            takeUntil(this.viewLeft$),
            debounceTime(500),
        )
            .subscribe(search => {
                this.UserSettings.activitySearch = search;
                this.refreshActivityList();
            });

        this.mediaObserver.asObservable()
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(() => {
                this.isSmallScreen = this.mediaObserver.isActive('lt-lg');
            });

            this.refreshActivityList();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * handelt das ändern am Paginator
     * 
     * @param {PageEvent} event Paginator Event
     */
    public handlePageEvent(event: PageEvent) {
        this.UserSettings.activityPaging.currentPage = event.pageIndex + 1;
        this.UserSettings.activityPaging.entityCountPerPage = event.pageSize;
        this.refreshActivityList();
    }

    /**
     * open the Usermanagement
     */
    // eslint-disable-next-line class-methods-use-this
    public openUserManagement(): void {
        AccountController.openUserManagement();
    }

    /**
     * aktualisiert die Aktivitätenliste je nach filter bzw. sortierung
     */
    public refreshActivityList(): void {
        const showWaiterTimeout = setTimeout(() => {
            this.isLoading = true;
        }, GlobalSettings.waiterDelayShort);

        this.dataService.refreshActivityList()
            .then(() => {
                if (showWaiterTimeout) {
                    clearTimeout(showWaiterTimeout);
                }
                this.isLoading = false;
            })
            .catch(e => {
                if (showWaiterTimeout) {
                    clearTimeout(showWaiterTimeout);
                }
                this.isLoading = false;
                throw e;
            });
    }

    /**
     * setzt alle Filter und Sortierungen zurück
     */
    public resetAllFilters(): void {
        this.UserSettings.activityFilterConsult = GlobalSettings.defaultActivityFilter;
        this.UserSettings.activityFilterBranch = [];
        this.UserSettings.activitySort = GlobalSettings.defaultActivitySort;
        this.UserSettings.activitySearch = GlobalSettings.defaultActivitySearch;
        this.refreshActivityList();
    }

    /**
     * navigiert zu einer unterseite
     * 
     * @param {string} view Link zur Unterseite
     * @returns {Promise<boolean>} navigations promise
     */
    public onNavigationBtnClicked(view: string): Promise<boolean> {
        return this.router.navigate([view], { relativeTo: this.route });
    }

    /**
     * navigiert zum Impressum
     * 
     * @returns {Promise<boolean>} navigations promise
     */
    public imprint(): Promise<boolean> {
        return this.router.navigateByUrl('/imprint');
    }

    /**
     * navigiert zum Handbuch
     */
    // eslint-disable-next-line class-methods-use-this
    public manual(): void {
        window.open('./manual/index.html', '_blank');
    }

    /**
     * navigiert zu Release-Notes
     */
    // eslint-disable-next-line class-methods-use-this
    public releaseNotes(): void {
        this.releaseNotesController.getAllReleaseNotes().subscribe(releaseNotes => {
            this.ntagReleaseNoteService.showReleases(releaseNotes);
        })
    }

    /**
     * setzt die Suche zurück
     */
    public clearSearch(): void {
        this.UserSettings.activitySearch = '';
        this.refreshActivityList();
    }

    /**
     * Aktualisiert eine Aktivität bei Änderung des Gelesen Status
     * 
     * @param  {IActivityModel} activity Zu aktualisierende Aktivität
     * @param  {MatCheckboxChange} change Change Event der Checkbox
     * @returns {Promise<void>} Void Promise
     */
    public updateActivity(activity: IActivityModel, change: MatCheckboxChange): Promise<void> {
        return this.dataService.updateActivity(activity.id, change.checked);
    }

    /**
     * Zählt die Anzahl der ungelesenen Aktivitäten eines Clusters
     * 
     * @param  {IActivityCluster} cluster Cluster zum Zählen der ungelesenen Aktivitäten
     * @returns {number} Anzahl der ungelesenen Aktivitäten
     */
    // eslint-disable-next-line class-methods-use-this
    public countUnreadActivities(cluster: IActivityCluster): number {
        return cluster.data.filter(activity => !activity.isRead).length;
    }

    /**
     * Funktion führt zu Financing Map
     * 
     * @param {string} customerId Customer Id
     * @param {string} financingMapId FinancingMap Id
     * @param {IActivityModel[]} activities abzuhakende Aktivitäten
     */
    public onFinancingMap(customerId: string, financingMapId: string, activities: IActivityModel[]): void {
        const mustSetCheckedIds = activities.reduce<string[]>((acc, curr) => (!curr.isRead ? [...acc, curr.id] : acc), []);
        const promisses = mustSetCheckedIds.map(id => this.dataService.updateActivity(id, true));

        Promise.all(promisses)
            .then(() => this.router.navigateByUrl(`customer/${customerId}/${financingMapId}/map/debtor`))
            .catch(e => { throw e; });
    }
}
