import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentViewerComponent } from '@ucba/sdk/components';
import { RoutingParams } from '@ucba/sdk/enums';

import { environment } from '../environments/environment';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SigninSamlComponent } from './components/signin-saml/signin-saml.component';
import { AuthGuard } from './guards/auth.guard';

/**
 * Guard um zu prüfen ob der alte Login noch funktioniert
 */
class AuthLocalGuard {
    /**
     * @override
     * @returns {boolean} kann route aktiviert werden
     */
    // eslint-disable-next-line class-methods-use-this
    public canActivate(): boolean {
        return !!environment.authConfiguration?.enabled;
    }
}

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        pathMatch: 'full',
        redirectTo: '',
    },
    {
        path: environment.authConfiguration?.loginURL ?? '',
        component: LoginComponent,
        canActivate: [AuthLocalGuard],
    },
    {
        path: 'auth/signin-saml',
        component: SigninSamlComponent,
        canActivate: [],
    },
    {
        path: 'auth/logout',
        component: LogoutComponent,
        canActivate: [],
    },
    {
        path: environment.authConfiguration?.registrationURL ?? '',
        component: RegistrationComponent,
        canActivate: [AuthLocalGuard],
    },
    {
        path: RoutingParams.CUSTOMER_MODULE,
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'document-viewer',
        component: DocumentViewerComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'imprint',
        component: ImprintComponent,
        canActivate: [],
    },
];

/**
 * Routing Modul für App Routen
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        AuthLocalGuard,
    ],
})
export class AppRoutingModule { }
